import React from "react";
import Modal from 'react-modal'


import './style.css'
import Header from '../../components/header';
import Footer from "../../components/footer";

Modal.setAppElement('#root');

export default function Projetos() {
    

    return (
        <div className="container">
            <Header />
            
            <div className="body">
                <div className="content">
                    <span>Projetos</span>
                    <div className="project">
                        <div>
                            <h1>Projeto Ensinando a Viver - Itapebussu</h1>
                            <p>Endereço: Rua Euvaldo Marques, 306 - Telefone (85) 98910-2430/ (85) 98730-6182  </p>
                            <p>Crianças atendidas: 400</p>
                            <p>Faixa etária: 1 - 22 anos</p>
                            <h2>Atividades Realizadas</h2>
                            <ul>
                                <li>Área Física: Avaliação de saúde, Futebol, jogos recreativos, vôlei;</li>
                                <li>Área cognitiva: reforço escolar, musicalização, </li>
                                <li>Área Socioemocional: Passeios de convivência e gincanas</li>
                                <li>Atividades de serviço: Passeatas de concientização</li>
                                <li>Oficinas: violão, computação, artes manuais;</li>
                            </ul>

                            <h2>Dias de Atendimento</h2>
                            <p>Terça-feira a Sábado - manhã e tarde</p>

                            {/* <h2>Histórico breve</h2> */}
                            {/* <p></p> */}
                        </div>
                        

                    </div>

                    <div className="project">
                        <div>
                            <h1>Projeto Ensinando a Viver - Amanari</h1>
                            <p>Endereço: Rua Acre, S/N - Telefone (85) 98910-2430/ (85) 98730-6182 </p>
                            <p>Crianças atendidas: 403</p>
                            <p>Faixa etária: 1 - 22 anos</p>
                            <h2>Atividades Realizadas</h2>
                            <ul>
                                <li>Área Física: Avaliação de saúde, Futebol, jogos recreativos, vôlei;</li>
                                <li>Área cognitiva: reforço escolar, musicalização, </li>
                                <li>Área Socioemocional: Passeios de convivência e gincanas</li>
                                <li>Atividades de serviço: Passeatas de concientização</li>
                                <li>Oficinas: violão, computação, artes manuais;</li>
                            </ul>

                            <h2>Dias de Atendimento</h2>
                            <p>Terça-feira a Sábado - manhã e tarde</p>

                            {/* <h2>Histórico breve</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mattis finibus dui, ac congue mauris tempor eget. Vivamus rhoncus erat eu vestibulum maximus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent non commodo est. Nulla facilisi. Etiam ornare tortor sit amet est ultrices, sed elementum arcu accumsan. Vestibulum vel felis eu nisi semper laoreet sed non urna. Integer nec pharetra dui, quis mollis enim. Mauris id molestie neque. Sed vitae enim felis. Nam ut bibendum risus. Praesent dignissim interdum tortor eu pellentesque. Proin vel dui vel quam vulputate porta sed ac erat. Nam diam libero, sollicitudin at imperdiet sed, blandit non sem. Donec volutpat, est et ornare consequat, orci elit sollicitudin quam, vel iaculis nibh arcu id massa. Nullam et nibh vitae erat auctor dignissim non at enim.</p> */}
                        </div>
                        
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}